import firebase from '@/services/firebase'
import analytics from '@/services/analytics'
import dateServices from '@/services/dateServices'
import tiktokPixel from '@/services/tiktokPixel'
import metaPixel from '@/services/metaPixel'
import {getFormattedFilterValue, getMetaLocationParams} from "@/utils/analysis";

function sendAnalyticsLogEvent(event, params) {
    if (process.client) {
        params = addSignInParams(params)
        console.log('Google Analytics:', event, params)
        firebase.sendAnalyticsLogEvent(event, params)
        if (window.dataLayer) {
            window.dataLayer.push(params);
        }
    }
}
function sendSignUp(method) {
    method = method.toLowerCase()
    tiktokPixel.sendCompleteRegistration(method)
    metaPixel.sendCompleteRegistration(method)
    sendAnalyticsLogEvent('sign_up', {
        method
    })
}
function sendLogin(method) {
    sendAnalyticsLogEvent('login', {
        method: method.toLowerCase()
    })
}

// view_type for view_search_results event
function sendViewSearchResult(
    space,
    date = dateServices.getToday(),
    searchTerm,
    time,
    viewType = 'no source',
    filterObj = {},
    firstFiveHitsSlugList,
    country,
    city,
    region
) {
    searchTerm = searchTerm || '[Empty]'
    date = analytics.getDate(date)
    const spaceType = analytics.getBookType(space)

    tiktokPixel.sendSearch(spaceType, date, searchTerm)
    const params = {
        book_type: spaceType,
        date,
        search_term: searchTerm,
        view_type: viewType,
        time
    }
    params.filter = getFormattedFilterValue(filterObj)
    metaPixel.sendSearch({...params, ...
            {
                content_ids: firstFiveHitsSlugList,
                country,
                city,
                region
            }
        })
    sendAnalyticsLogEvent('view_search_results', params)
}
function sendFilter(params) {
    sendAnalyticsLogEvent('filter', params)
}

function sendApplyQuickFilter(source = 'no source', filterObj) {
    const params = {
        click_from: source,
    }
    params.filter = getFormattedFilterValue(filterObj)
    sendAnalyticsLogEvent('apply_quick_filter', params)
}

// view_button_type for view_item
function sendViewItem(
    btn = 'no source',
    slug,
    name,
    space = 'hotel',
    startDate = dateServices.getToday(),
    endDate,
    area,
    country,
    city,
    region
) {
    const spaceType = analytics.getBookType(space)
    tiktokPixel.sendViewContent(btn, slug, name, spaceType, startDate, endDate)
    const params = {
        item_id: slug,
        start_date: startDate,
        end_date: endDate,
        google_business_vertical: 'hotel_rental',
        book_type: spaceType,
        view_button_type: btn,
        property_name: name,
        property_ID: slug,
        area
    }
    metaPixel.sendViewContent({...params, ...
            {
                country,
                city,
                region
            }
    })
    sendAnalyticsLogEvent('view_item', params)
}
function sendSelectCheckAvailability({ name, slug, type }) {
    const params = {
        property_name: name,
        property_ID: slug,
        check_type: type
    }
    sendAnalyticsLogEvent('select_check_availability', params)
}
function sendSelectCheckInDate({ source, name, slug, date }) {
    const params = {
        click_from: source,
        date: analytics.getDate(date),
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('select_check_in_date', params)
}
function sendSelectBookingLength({ name, slug, bookType }) {
    const params = {
        duration_type: bookType,
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('select_booking_length', params)
}

function sendSelectCheckInTime({ name, slug, startTime }) {
    const params = {
        check_in_time: analytics.getTime(startTime),
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('select_check_in_time', params)
}

function sendSelectCheckOutTime({ name, slug, endTime }) {
    const params = {
        check_out_time: analytics.getTime(endTime),
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('select_check_out_time', params)
}

function sendSelectPurchaseOption({ name, slug, venueType, venueName }) {
    const params = {
        purchase_option_ID: venueType,
        purchase_option_name: venueName,
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('select_purchase_option', params)
}

function sendAddPaymentInfo(source, day) {
    const params = {
        click_from: source
    }
    if (day) {
        tiktokPixel.sendAddPaymentInfo(day)
        params.date_after_registration = day
    }
    sendAnalyticsLogEvent('add_payment_info', params)
}

function sendBeginCheckout(params) {
    params.spaceType = analytics.getBookType(params.space)
    params.checkInTime = analytics.getTime(params.startTime)
    params.checkOutTime = analytics.getTime(params.endTime)
    params.date = analytics.getDate(params.date)

    tiktokPixel.sendInitiateCheckout(params)
    metaPixel.sendInitiateCheckout(params)
    const p = {
        item_id: params.slug,
        start_date: params.startDate,
        end_date: params.endDate,
        google_business_vertical: 'hotel_rental',
        book_type: params.spaceType,
        check_in_time: params.checkInTime,
        check_out_time: params.checkOutTime,
        date: params.date,
        duration_type: params.bookType,
        purchase_option_ID: params.venue,
        purchase_option_name: params.venueName,
        property_name: params.name,
        property_ID: params.slug
    }
    sendAnalyticsLogEvent('begin_checkout', p)
}

function sendViewPaymentMethod(name, slug) {
    const params = {
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('view_payment_method', params)
}
function sendEcommercePurchase(params) {
    params.date = analytics.getDate(params.date)
    tiktokPixel.sendCompletePayment(params)
    metaPixel.sendPurchase(params)
    /* value & tax needed in GA? */
    const p = {
        item_id: params.slug,
        start_date: params.startDate,
        end_date: params.endDate,
        google_business_vertical: 'hotel_rental',
        book_type: params.bookType,
        check_in_time: params.startTime,
        check_out_time: params.endTime,
        date: params.date,
        duration_type: params.durationType,
        property_name: params.name,
        property_ID: params.slug,
        purchase_option_name: params.venueName,
        purchase_option_ID: params.venueType,
        paid_amount: params.paidAmount,
        original_amount: params.originalAmount,
        value: params.value,
        tax: params.tax,
        voucher_name: analytics.getVoucherName(params.statement),
        discount: params.discount,
        payment_method: params.paymentMethod,
        currency: params.currency,
        reserve_ID: params.bookingId
    }
    sendAnalyticsLogEvent('ecommerce_purchase', p)
}
function sendCancelBooking(params) {
    sendAnalyticsLogEvent('cancel_booking', params)
}
function sendAddToWishList({ name, slug, space }) {
    const spaceType = analytics.getBookType(space)
    const startDate = dateServices.getToday()

    tiktokPixel.sendAddToWishlist(name, slug, spaceType, startDate)
    metaPixel.sendAddToWishlist(name, slug, spaceType, startDate)
    const params = {
        item_id: slug,
        start_date: startDate,
        end_date: '',
        google_business_vertical: 'hotel_rental',
        book_type: spaceType,
        property_name: name,
        property_ID: slug
    }
    sendAnalyticsLogEvent('add_to_wishlist', params)
}
function sendChatbotButtonClicked() {
    const params = {}
    sendAnalyticsLogEvent('click_chatbot_button', params)
}

function addSignInParams(params) {
    params.member_id = firebase.getUserId()
    if (!firebase.isAnonymous()) {
        params.user_id = firebase.getUserId()
    }
    return params
}
function sendViewOfferArticles(source) {
    if (source) {
        const params = {
            click_from: source,
            // view_from: source,
            // slug,
            // title
        }
        // if (source?.includes('Homepage_Current')) {
        //     params.home_page_position = position
        // }
        sendAnalyticsLogEvent('view_current_offers', params)
    }
}
function sendViewCurrentOffersList(source) {
    if (source) {
        const params = {
            click_from: source
        }
        sendAnalyticsLogEvent('view_current_offers_list', params)
    }
}
function sendSocialShareClicked(type, platform) {
    const params = {
        click_from: type,
        platform
    }
    sendAnalyticsLogEvent('click_social_share', params)
}

// function sendViewLocationPage(source = 'no source', position, name) {
//     const params = {
//         click_from: source,
//         name
//     }
//     if (source === 'homepage') params.home_page_position = position
//     sendAnalyticsLogEvent('view_location_page', params)
// }

function sendFastSearchButtonClicked(type, value) {
    const params = {
        click_from: type
    }
    if (value) {
        params.value = value
    }
    sendAnalyticsLogEvent('click_fast_search_button', params)
}

function sendMagicSearchButtonClicked(source, value) {
    const params = {
        click_from: source
    }
    if (value) {
        params.value = value
    }
    sendAnalyticsLogEvent('click_magic_search_button', params)
}

// function sendDestinationButtonClicked(slug) {
//     const params = {
//         slug
//     }
//     sendAnalyticsLogEvent('click_popular_destination_button', params)
// }

function sendSort(source, value) {
    if (value) {
        const params = {
            click_from: source,
            sort_item: value
        }
        sendAnalyticsLogEvent('sort', params)
    }
}

function sendPopularCheckInTimeButtonClicked(source, type, value, date) {
    const params = {
        click_from: source,
        date: analytics.getDate(date),
        type,
        time: value
    }
    sendAnalyticsLogEvent('click_check_in_time_button', params)
}

function sendViewBookingSummary(source = 'no source') {
    const params = {
        click_from: source
    }
    sendAnalyticsLogEvent('view_booking_summary', params)
}

function sendViewBlog(source = 'no source') {
    const params = {
        click_from: source
    }
    sendAnalyticsLogEvent('view_blog', params)
}
// function sendViewBlogList(source) {
//     if (source) {
//         const params = {
//             click_from: source
//         }
//         sendAnalyticsLogEvent('view_blog_list', params)
//     }
// }

function sendViewMap(source = 'no source') {
    const params = {
        click_from: source
    }
    sendAnalyticsLogEvent('view_map', params)
}

function sendScrollPhotos(source = 'no source') {
    const params = {
        click_from: source
    }
    sendAnalyticsLogEvent('scroll_photos', params)
}

function sendViewPhotos(source = 'no source') {
    const params = {
        click_from: source
    }
    sendAnalyticsLogEvent('view_photos', params)
}

export default {
    sendSignUp,
    sendLogin,
    sendViewSearchResult,
    sendFilter,
    sendApplyQuickFilter,
    sendViewItem,
    sendSelectCheckAvailability,
    sendSelectCheckInDate,
    sendSelectBookingLength,
    sendSelectCheckInTime,
    sendSelectCheckOutTime,
    sendSelectPurchaseOption,
    sendBeginCheckout,
    sendViewPaymentMethod,
    sendEcommercePurchase,
    sendCancelBooking,
    sendAddToWishList,
    sendAddPaymentInfo,
    sendChatbotButtonClicked,
    sendSocialShareClicked,
    sendViewOfferArticles,
    sendViewCurrentOffersList,
    // sendViewLocationPage,
    sendFastSearchButtonClicked,
    sendMagicSearchButtonClicked,
    // sendDestinationButtonClicked,
    sendSort,
    sendPopularCheckInTimeButtonClicked,
    sendViewBookingSummary,
    sendViewBlog,
    // sendViewBlogList,
    sendViewMap,
    sendScrollPhotos,
    sendViewPhotos,
}
